import API from "../utils/API";

export class TriggerConfigurationService {
  private page = 0;
  private size = 0;
  private triggerConfigurations: any;
  private activeNetworkCall = false;
  private networkSync = false;

  constructor() {
    this.triggerConfigurations = [];
  }

  private async fetchTriggerConfigurations(page = this.page, limit: number) {
    this.activeNetworkCall = true;
    try {
      const res = await API.get(`/triggers?page=${page}&limit=${limit}`);

      const response = res.data;
      const data = response.data;
      if (!this.triggerConfigurations.length && response.size) {
        this.triggerConfigurations = Array(response.size);
      }
      const start = this.page * limit;
      this.triggerConfigurations = [
        ...this.triggerConfigurations.slice(0, start),
        ...data,
        ...this.triggerConfigurations.slice(start + data.length),
      ];
      this.size = response.size ? response.size : this.size;
      if (!this.networkSync) {
        this.networkSync = true;
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.activeNetworkCall = false;
    }
  }

  private async fetchTriggerConfiguration(id: string) {
    const res = await API.get(`/triggers/${id}`);
    return res.data;
  }

  private async postTriggerConfig(
    triggerConfig: any,
    id: string | null = null
  ) {
    this.activeNetworkCall = true;
    try {
      const request_operation = id ? "put" : "post";
      const res = await API({
        method: request_operation,
        url: `/triggers/${id ? id : ""}`,
        data: triggerConfig,
      });
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      this.activeNetworkCall = false;
    }
  }

  async getTriggerConfigurations(limit: number) {
    const start = this.page * limit;
    const end = start + limit;

    if (!this.triggerConfigurations[start] && !this.activeNetworkCall) {
      await this.fetchTriggerConfigurations(this.page, limit);
    }

    if (this.triggerConfigurations[start]) {
      const data = this.triggerConfigurations.slice(start, end);
      return { data: data, size: this.size };
    }
    return { data: [], size: this.size };
  }

  private getIndexById(id: string) {
    return this.triggerConfigurations.findIndex((item:any) => item? item._id === id : false)
  }

  updatePage(page: number) {
    if (this.activeNetworkCall) {
      return;
    }
    this.page = page;
  }

  async getTriggerConfiguration(id: string) {
    if (this.triggerConfigurations.length) {
      return this.triggerConfigurations.find((item: any) =>
        item ? item._id === id : false
      );
    }
    const res = await this.fetchTriggerConfiguration(id);
    return res;
  }

  async addTriggerConfiguration(triggerConfig: any) {
    this.activeNetworkCall = true;
    try {
      const res = await this.postTriggerConfig(triggerConfig);
      if (!this.networkSync) {
        return;
      }
      this.triggerConfigurations = [...this.triggerConfigurations, res.data];
      this.size = this.size + 1;
      this.page = 0;
    } catch (err) {
    } finally {
      this.activeNetworkCall = false;
    }
  }

  async updateTriggerConfiguration(triggerConfiguration: any, id: string) {
    this.activeNetworkCall = true;
    try {
      await this.postTriggerConfig(triggerConfiguration, id);
      if (!this.networkSync) {
        return;
      }
      const idx = this.getIndexById(id);
      if (idx < 0) {
        return;
      }
      triggerConfiguration._id = id;
      this.triggerConfigurations = [
        ...this.triggerConfigurations.slice(0, idx),
        triggerConfiguration,
        ...this.triggerConfigurations.slice(idx + 1),
      ];
      this.page = 0;
    } catch (err) {
    } finally {
      this.activeNetworkCall = false;
    }
  }

  async deleteTriggerConfigurationById(id: string) {
    if (this.activeNetworkCall) {
      return;
    }
    this.activeNetworkCall = true;
    try {
      await API.delete(`/triggers/${id}`);
      const idx = this.getIndexById(id);
      this.triggerConfigurations = [
        ...this.triggerConfigurations.slice(0, idx),
        ...this.triggerConfigurations.slice(idx + 1),
      ];
      this.size = this.size - 1;
    } catch (err) {
    } finally {
      this.activeNetworkCall = false;
    }
  }
}

export const triggerConfigurationService = new TriggerConfigurationService();
