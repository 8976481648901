import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './App.css';
import NavBar from './components/NavBar/NavBar';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { TriggerConfigurationList } from './components/Trigger/triggerConfigurationList';
import triggerConfigurationForm from './components/Trigger/triggerConfigurationForm';
import { EventList } from './components/Event/events';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuditList } from './components/Audit/audits';


function App() {
  return (
    <BrowserRouter>
      <Container fluid>
        <Row>
          <Col>
            <NavBar />
          </Col>
        </Row>
        <Row>
          <Col>
            <Switch>
              <Route path="/" exact component={EventList} />
              <Route path="/triggers" exact component={TriggerConfigurationList} />
              <Route path="/triggers/add" exact component={triggerConfigurationForm} />
              <Route path="/triggers/edit/:id" exact component={triggerConfigurationForm} />
              <Route path="/events" exact component={EventList} />
              <Route path="/audits" exact component={AuditList} />
            </Switch>
          </Col>
        </Row>
      </Container>

    </BrowserRouter>

  );
}

export default App;
