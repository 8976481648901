import React, { useEffect, useState } from 'react'
import io from 'socket.io-client'
import { Pagination, Button, Table, Collapse } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { SearchBar } from '../shared/searchBar'
import moment from 'moment'
import { updateUserEvent, getUserEvent } from '../../services/eventService'


export function EventList() {
  const [paginationItems, setPaginationItems] = useState([]);
  const [openIndexes, setOpenIndexes] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [active, setActive] = useState(1);
  const [loading, setLoading] = useState(false);



  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_IO);
    socket.on("event", msg => {
      msg = JSON.parse(msg)
      if (msg.type === 'USER') {
        msg['newContent'] = true;
        const data = [msg].concat(tableData)
        setTableData(data);
      }
    });
    return () => {
      socket.disconnect();
    }
  }, [tableData]);

  const page = new URLSearchParams(useLocation().search).get('page')
  if (page) {
    setActive(Number(page))
  }

  async function updateApolloInfo(data) {
    const payload = {
      userId: data.identity.userId,
      videoId: data.parameters.video_id
    }
    const response = await updateUserEvent(data._id, payload)

    const newTableData = [...tableData];
    const index = newTableData.findIndex(x => x._id === data._id);
    newTableData[index] = response.data;
    setTableData(newTableData);
  }

  useEffect(() => {
    async function fetchUserEvent() {
      const response = await getUserEvent(active);
      let table_data = response.data.data;
  
      const _items = []
      const limit = response.data.size % response.data.limit > 1 ? (response.data.size / response.data.limit) + 1 : response.data.size / response.data.limit
      for (let number = 1; number <= limit; number++) {
        _items.push(
          <Pagination.Item key={number} active={number === active}>
            {number}
          </Pagination.Item>,
        );
      }
      setTableData(table_data);
      setPaginationItems(_items);
      setLoading(false);

    }
    
      setLoading(true);
      fetchUserEvent();

  }, [active])


  function changePagination(e) {
    setActive(Number(e.target.text))
  }



  const openCollapseBar = (index) => {
    if (openIndexes.indexOf(index) === -1) {
      return false;
    }
    return true;
  }


  const openAccordion = (index) => {
    if (openIndexes.indexOf(index) === -1) {
      setOpenIndexes([...openIndexes, index])
    } else {
      const filteredItems = openIndexes.filter(item => item !== index)
      setOpenIndexes(filteredItems)
    }
  }

  const handleSearchResult = (results) => {
    setTableData(results.table_data);
    setPaginationItems(results.items);
  }



  return (
    <>
      <SearchBar onSearch={handleSearchResult} active={active} isEvent={true} />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>User ID</th>
            <th>Event</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        { tableData.length > 0 &&
          tableData.map((data, index) => {
            return (
              <tbody key={index}>
                <tr onClick={() => { openAccordion(index) }} style={{backgroundColor:data.newContent ? '#8bbd8b47' : ''}}>
                  <td>{data.identity.userId ? data.identity.userId : '-'}</td>
                  <td>{data.event.type} - {data.event.subType}</td>
                  <td>{`${moment(data.created_at).local().format('YYYY-MM-DD HH:mm:ss')}`}</td>
                </tr>
                <tr>
                  <td colSpan="4" style={{ padding: '0px' }}>
                    <Collapse in={openCollapseBar(index)}>
                      <div style={{ 'padding': '5px' }}>
                        <code>{`${JSON.stringify(data.parameters)}`} </code>
                        {!data.isComplete &&
                          <Button variant="secondary" style={{ 'float': 'right' }} onClick={() => { updateApolloInfo(data) }}>Retry</Button>
                        }
                      </div>
                    </Collapse>
                  </td>
                </tr>
              </tbody>
            )
          })
        }
                  {tableData.length === 0 &&
          <tr>
            <td colSpan="4"> { loading ? 'Loading' : 'No Data' }</td>
          </tr>
          }

      </Table>

      <Pagination size="lg" onClick={changePagination}>{paginationItems}</Pagination>
      <br />
    </>
  )

}
