import React, { useEffect, useState } from "react";
import { withFormik, FormikProps } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import { Col, Row, Form as BSForm, Button } from "react-bootstrap";
import { triggerConfigurationService } from "../../services/triggerConfigurationService";

interface FormValues {
  serviceName: string;
  eventType: string;
  eventSubType: string;
  triggerType: string;
  triggerDestination: string;
  timeFrame: string;
  numberOfOccurrence: string;
  _id: string;
}

interface OtherProps {
  message: string;
}
interface Event {
  type?: string;
  subType?: string;
}
interface TriggerConfiguration {
  _id?: string;
  serviceName?: string;
  event?: Event;
  triggerType?: string;
  triggerDestination?: string;
  timeFrame?: Number;
  numberOfOccurrence?: Number;
}

function TriggerConfigurationForm(props: OtherProps & FormikProps<FormValues>) {
  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    touched,
  } = props;
  return (
    <Row className="mt-5">
      <Col>
        <BSForm onSubmit={handleSubmit}>
          <div className="text-center">
            <h4>Trigger Configuration</h4>
          </div>
          <div
            className=""
            style={{ pointerEvents: isSubmitting ? "none" : undefined }}
          >
            <BSForm.Group>
              <BSForm.Label htmlFor="serviceName">Service</BSForm.Label>
              <BSForm.Control
                as="select"
                name="serviceName"
                value={values.serviceName}
                onChange={handleChange}
                isInvalid={touched.serviceName && !!errors.serviceName}
                placeholder="Service"
              >
                <option value="apollo">Apollo</option>
                <option value="ivy">IVY</option>
              </BSForm.Control>
              <BSForm.Control.Feedback type="invalid">
                {errors.serviceName}
              </BSForm.Control.Feedback>
            </BSForm.Group>

            <BSForm.Group>
              <BSForm.Label htmlFor="eventType">Event Type</BSForm.Label>
              <BSForm.Control
                name="eventType"
                value={values.eventType}
                onChange={handleChange}
                isInvalid={touched.eventType && !!errors.eventType}
                type="text"
              />
              <BSForm.Control.Feedback type="invalid">
                {errors.eventType}
              </BSForm.Control.Feedback>
            </BSForm.Group>

            <BSForm.Group>
              <BSForm.Label htmlFor="eventSubType">Event Subtype</BSForm.Label>
              <BSForm.Control
                name="eventSubType"
                value={values.eventSubType}
                onChange={handleChange}
                isInvalid={touched.eventSubType && !!errors.eventSubType}
                type="text"
              />
              <BSForm.Control.Feedback type="invalid">
                {errors.eventSubType}
              </BSForm.Control.Feedback>
            </BSForm.Group>

            <BSForm.Group>
              <BSForm.Label htmlFor="triggerType">Trigger Type</BSForm.Label>
              <BSForm.Control
                as="select"
                name="triggerType"
                value={values.triggerType}
                onChange={handleChange}
                isInvalid={touched.triggerType && !!errors.triggerType}
                placeholder="Service"
              >
                <option value="slack">Slack</option>
                <option value="email">Email</option>
              </BSForm.Control>
              <BSForm.Control.Feedback type="invalid">
                {errors.triggerType}
              </BSForm.Control.Feedback>
            </BSForm.Group>

            <BSForm.Group>
              <BSForm.Label htmlFor="triggerDestination">
                Trigger Destination
              </BSForm.Label>
              <BSForm.Control
                name="triggerDestination"
                value={values.triggerDestination}
                onChange={handleChange}
                isInvalid={
                  touched.triggerDestination && !!errors.triggerDestination
                }
                type="text"
              />
              <BSForm.Control.Feedback type="invalid">
                {errors.triggerDestination}
              </BSForm.Control.Feedback>
            </BSForm.Group>

            <BSForm.Group>
              <BSForm.Label htmlFor="timeFrame">
                Time Frame (in seconds)
              </BSForm.Label>
              <BSForm.Control
                name="timeFrame"
                value={values.timeFrame}
                onChange={handleChange}
                isInvalid={touched.timeFrame && !!errors.timeFrame}
                type="number"
                min="0"
              />
              <BSForm.Control.Feedback type="invalid">
                {errors.timeFrame}
              </BSForm.Control.Feedback>
            </BSForm.Group>

            <BSForm.Group>
              <BSForm.Label htmlFor="numberOfOccurrence">
                Number of occurrence
              </BSForm.Label>
              <BSForm.Control
                name="numberOfOccurrence"
                value={values.numberOfOccurrence}
                onChange={handleChange}
                isInvalid={
                  touched.numberOfOccurrence && !!errors.numberOfOccurrence
                }
                type="number"
                min="0"
              />
              <BSForm.Control.Feedback type="invalid">
                {errors.numberOfOccurrence}
              </BSForm.Control.Feedback>
            </BSForm.Group>

            <Button type="submit">
              {values._id ? "Update" : "Add"} trigger
            </Button>
          </div>
        </BSForm>
      </Col>
    </Row>
  );
}

const MyForm = withRouter(
  withFormik({
    mapPropsToValues(props: any) {
      const id = props.match.params.id;
      let config: TriggerConfiguration = {};
      config = props.formData;

      return {
        _id: id,
        serviceName: config.serviceName || "apollo",
        eventType: config.event ? config.event.type : "",
        eventSubType: config.event ? config.event.subType : "",
        triggerType: config.triggerType || "slack",
        triggerDestination: config.triggerDestination || "",
        timeFrame: config.timeFrame || 0,
        numberOfOccurrence: config.numberOfOccurrence || 0,
      };
    },
    validate: (values) => {
      values.eventType = values.eventType.toUpperCase();
      values.eventSubType = values.eventSubType.toUpperCase();
    },
    validationSchema: Yup.object().shape({
      serviceName: Yup.string().trim().required("Service is required"),
      eventType: Yup.string().trim().required("Event type is required"),
      eventSubType: Yup.string().trim().required("Event subtype is required"),
      triggerType: Yup.string().trim().required("Trigger type is required"),
      triggerDestination: Yup.string()
        .trim()
        .required("Trigger destination is required"),
    }),
    handleSubmit( values: any, { props, setSubmitting }) {
      const data = {
        serviceName: values.serviceName,
        event: {
          type: values.eventType,
          subType: values.eventSubType,
        },
        triggerType: values.triggerType,
        triggerDestination: values.triggerDestination,
        timeFrame: values.timeFrame,
        numberOfOccurrence: values.numberOfOccurrence,
      };

      let res = values._id
        ? triggerConfigurationService.updateTriggerConfiguration(
            data,
            values._id
          )
        : triggerConfigurationService.addTriggerConfiguration(data);
      res
        .then((response) => {
          setSubmitting(false);
          props.history.push("/triggers");
        })
        .catch((error) => {
          setSubmitting(false);
        });
    },
  })(TriggerConfigurationForm)
);

export default function TriggerForm(props: any) {
  const [response, setResponse] = useState<any>(null);

  function showForm() {
    if (response != null) {
      return <MyForm formData={response} />;
      // return <h1>Loading...</h1>
    } else {
      return <h1>Loading...</h1>;
    }
  }

  useEffect(() => {
    const id = props.match.params.id;

    if (id) {
      triggerConfigurationService.getTriggerConfiguration(id).then(res => setResponse(res))
    } else {
      setResponse({
        serviceName: "apollo",
        eventType: "",
        eventSubType: "",
        triggerType: "slack",
        triggerDestination: "",
        timeFrame: 0,
        numberOfOccurrence: 0,
      });
    }
  }, [props.match.params.id]);

  return (
    <>
      {
        showForm()
      }
    </>
  );
}
