import React, { useState } from 'react'
import { Pagination, Form, Row, Col, Button } from 'react-bootstrap'
import "../../../node_modules/react-datetime/css/react-datetime.css";
import * as Datetime from "react-datetime";
import moment from 'moment'
import { searchUserEvent, searchAuditEvent } from '../../services/eventService';

export function SearchBar(props) {
    const [formData, setFormData] = useState({});


    async function onFormSubmit(event) {
        event.preventDefault();
        let query = '';
        Object.entries(formData).forEach(([key, value], index) => {
            if (index === 0) {
                query += `${key}=${value}`
            } else {
                query += `&${key}=${value}`
            }
        });
        let response;

        if (props.isEvent) {
            response = await searchUserEvent(query);
        } else {
            response = await searchAuditEvent(query);
        }
        let table_data = response.data.data;
        const _items = []
        const limit = response.data.data.size % 100 > 1 ? (response.data.data.size / 100) + 1 : response.data.data.size / 100
        for (let number = 1; number <= limit; number++) {
            _items.push(
                <Pagination.Item key={number} active={number === props.active}>
                    {number}
                </Pagination.Item>,
            );
        }
        props.onSearch({ table_data: table_data, pagination: _items });

    }

    function handleServiceNameChange(event) {
        setFormData({ ...formData, serviceName: event.target.value });
    }

    function handleStartDateChange(datetime) {
        if (moment(datetime).isValid()) {
            setFormData({ ...formData, startDate: moment(datetime).toISOString() });
        }
    }

    function handleEndDateChange(datetime) {
        if (moment(datetime).isValid()) {
            setFormData({ ...formData, endDate: moment(datetime).toISOString() });
        }
    }

    function handleUserIDChange(event) {
        setFormData({ ...formData, userDetail: event.target.value });
    }
    return (
        <Form onSubmit={onFormSubmit}>
            <Row>
                <Col>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Control as="select" onChange={handleServiceNameChange}>
                            <option>All</option>
                            <option>apollo</option>
                            <option>ivy</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Control type="text" placeholder="User ID/User Email" onChange={handleUserIDChange} />
                    </Form.Group>
                </Col>
                <Col>
                    <Datetime inputProps={{ placeholder: 'Start Date' }} onChange={handleStartDateChange} />
                </Col>
                <Col>
                    <Datetime inputProps={{ placeholder: 'End Date' }} onChange={handleEndDateChange} />
                </Col>
                <Col>
                    <Button variant="primary" type="submit">
                        Submit
</Button>
                </Col>
            </Row>
        </Form>
    )

}