import API from '../utils/API';

export async function updateUserEvent(id, data){
    return await API.put(`/user-events/${id}/update-apollo-info/`, data);
}

export async function getUserEvent(index){
    return await API.get(`/user-events?page=${index}`);
}

export async function getAuditEvent(index){
    return await API.get(`/audit-events?page=${index}`);
}

export async function updateAuditEvent(id, data){
    return await API.put(`/audit-events/${id}/update-apollo-info/`, data);
}

export async function searchUserEvent(query){
    return await API.get(`/user-events?${query}`);
}
export async function searchAuditEvent(query){
    return await API.get(`/audit-events?${query}`);
}