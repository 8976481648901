import React, { useState, useEffect } from "react";
import { Table, Button, Pagination, Modal } from "react-bootstrap";
import { triggerConfigurationService } from "../../services/triggerConfigurationService";
import { Link } from "react-router-dom";

interface Event {
  type?: string;
  subType?: string;
}
interface Config {
  _id?: string;
  serviceName?: string;
  event?: any;
  triggerType?: string;
  triggerDestination?: string;
}
interface State {
  triggerConfigs: Config[];
}
interface Props {
  history: Array<any>;
}

export function TriggerConfigurationList(props: any) {
  const [triggerConfigs, setTriggerConfigs] = useState<Config[]>([]);
  const [paginationItems, setPaginationsItems] = useState<JSX.Element[]>([]);
  const [active, setActive] = useState(1);
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [update, forceUpdate] = useState({});
  const limit = 10;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    triggerConfigurationService.getTriggerConfigurations(limit).then((res: any) => {
      setTriggerConfigs(res.data);
      let pages = Math.floor(
        Math.trunc(res.size % limit) > 1 ? res.size / limit + 1 : res.size / 10
      );

      if (pages && active > pages) {
        triggerConfigurationService.updatePage(pages - 1);
        setActive(pages);
      }
      const items = [];
      for (let number = 1; number <= pages; number++) {
        items.push(
          <Pagination.Item key={number} active={number === active}>
            {number}
          </Pagination.Item>
        );
      }
      setPaginationsItems(items);
    });
  }, [active, update]);

  function showDeleteDialogShow(id: any) {
    setSelectedId(id);
    handleShow();
  }

  async function deleteTrigger() {
    await triggerConfigurationService.deleteTriggerConfigurationById(selectedId);
    forceUpdate({});
    handleClose();
  }

  function changePagination(e: any) {
    if (e.target.textContent.includes("current")) {
      return;
    }
    const page = Number(e.target.text);
    triggerConfigurationService.updatePage(page - 1);
    setActive(page);
  }

  return (
    <div>
      <div className="link">
        <Link to="/triggers/add">
          <Button style={{ float: "right" }}>Add New</Button>
        </Link>
      </div>
      <Table bordered hover responsive>
        <thead>
          <tr>
            <th>S.N</th>
            <th>Service</th>
            <th>Event Type</th>
            <th>Trigger Type</th>
            <th>Trigger Destination</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {triggerConfigs.map((config, index) => {
            return (
              <tr
                key={index}
                onClick={() =>
                  props.history.push(`/triggers/edit/${config._id}`)
                }
              >
                <td>{((active - 1) * 10) + index + 1}</td>
                <td>{config.serviceName}</td>
                <td>
                  {config.event.type} {config.event.subType}
                </td>
                <td>{config.triggerType}</td>
                <td>{config.triggerDestination}</td>
                <td
                  className="actionButtons"
                  onClick={(event) => {
                    event.stopPropagation();
                    showDeleteDialogShow(config._id);
                  }}
                >
                  <p>&#10060;</p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Pagination size="lg" onClick={changePagination}>
        {paginationItems}
      </Pagination>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={deleteTrigger}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
