import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import { Nav} from 'react-bootstrap'
import live from '../../assets/images/live.png'

export default function NavBar() {
  return (
    <>
      <Navbar bg="light" expand="lg">
        <Link to="/">
          <img alt="live events" style={{height:'30px'}} src={live}></img>
          <Navbar.Brand>EVENTS</Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
              <Nav.Link as={Link} to="/triggers" >Home</Nav.Link>
              <Nav.Link as={Link} to="/events" >Events</Nav.Link>
              <Nav.Link as={Link} to="/audits" >Audits</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>

  )
}
